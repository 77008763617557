import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import GlobalStyles from "../../_global.scss";
import "./table.scss";

const Table = ({
  data,
  columns,
  indication,
  totalRecords,
  totalPages,
  pageSize,
  currentPage,
  setCurrentPage,
  setPageSize,
  refetch,
}) => {
  const {
    primaryColor,
    secondBgColor,
    mainBgColor,
    primaryColorWithAlpha,
    inputBgColor,
    inputBorderColor,
    inputTextColor,
  } = GlobalStyles;

  // console.log(currentPage);

  // MARK: PAGINATION STYLES
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      if (page === "<") {
        page = currentPage - 1;
      } else if (page === ">") {
        page = currentPage + 1;
      } else if (page === "<<") {
        page = 1;
        setCurrentPage(1);
      } else if (page === ">>") {
        page = totalPages;
        setCurrentPage(totalPages);
      }
      // console.log(page);
      onPageChange(page);
      setCurrentPage(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = inputBgColor;
      activeStyle.color = primaryColor;

      activeStyle.borderColor = primaryColor;
      activeStyle.borderSize = "5px";
      activeStyle.borderStyle = "solid";

      activeStyle.padding = "15px  30px";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
      activeStyle.fontSize = "larger";
    } else {
      activeStyle.backgroundColor = inputBorderColor;
      activeStyle.color = inputTextColor;
      activeStyle.padding = "15px";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
    }
    if (typeof page === "string") {
      // activeStyle.backgroundColor = '#0D0D1C';
      //   activeStyle.backgroundColor = inputBorderColor;
      activeStyle.color = inputTextColor;
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
      // activeStyle.border = '2px solid #0D0D1C';
      //   activeStyle.borderColor = primaryColorWithAlpha;
      //   activeStyle.borderSize = "5px";
      //   activeStyle.borderStyle = "solid";
    }
    return (
      <li className="page-item" key={page} style={{ padding: "5px" }}>
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  // MARK: SIZE PER PAGE
  // size per page Style
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div style={{ width: "100px" }}>
      <select
        value={currSizePerPage}
        onChange={(e) => {
          onSizePerPageChange(e.target.value);
          setPageSize(e.target.value);
        }}
        className="form-select select"
      >
        {options.map((option) => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  // MARK: pagination options
  const paginationOptions = {
    totalSize: totalRecords, // todo
    page: currentPage, // todo
    sizePerPage: pageSize, // todo

    pageButtonRenderer,
    sizePerPageRenderer,
  };

  return (
    <BootstrapTable
      remote
      onTableChange={refetch}
      bootstrap4
      keyField="id"
      data={data}
      columns={columns}
      bordered={false}
      noDataIndication={indication}
      pagination={paginationFactory(paginationOptions)}
    />
  );
};

export default Table;
