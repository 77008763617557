import { isRejectedWithValue } from "@reduxjs/toolkit";
import { removeToken } from "../services/localStorageService";

const sessionMiddleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { status } = action.payload || {};
    // const navigate = useNavigate();

    if (status === 401) {
      console.error("Unauthorized, logging out...");
      removeToken();
      // navigate("/account");
      window.location.reload();
    }
  }
  //   // console.log("Run sessionMiddleware");

  return next(action);
};

export default sessionMiddleware;
