import React, { useState } from "react";

const ImageWithPlaceholder = ({
  src,
  placeholder,
  alt,
  className,
  height,
  width,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div style={{ position: "relative", height, width }}>
      {/* Placeholder */}
      {!isLoaded && (
        <img
          src={placeholder}
          alt="placeholder"
          className={className}
          loading="lazy"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      )}

      {/* Main Image */}
      <img
        src={src}
        alt={alt}
        className={className}
        height={height}
        width={width}
        loading="lazy"
        style={isLoaded ? {} : { visibility: "hidden" }} // Hide until loaded
        onLoad={() => setIsLoaded(true)}
        onError={(e) => {
          e.target.onerror = null; // Prevent infinite loop in case placeholder fails
          e.target.src = placeholder; // Show placeholder on error
        }}
      />
    </div>
  );
};

export default ImageWithPlaceholder;
