import React, { useState } from "react";
import styles from "./selectFieldComponent.module.scss";

const SelectFieldComponent = ({ optionsList, firstOption, onSelectChange }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    // setSelectedValue(value);
    onSelectChange(value); // Send selected value to parent
  };

  return (
    <div>
      <select
        className={`${styles.select}`}
        onChange={handleSelectChange}
        defaultValue={firstOption}
      >
        <option value={firstOption} disabled hidden>
          {" "}
          {firstOption}{" "}
        </option>
        {optionsList.map((dt, index) => (
          // console.log(dt)
          <option value={dt?.value} key={index}>
            {" "}
            {dt?.label}{" "}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectFieldComponent;
