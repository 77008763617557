import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { useSentNotificationUserMutation } from "../../Data/services/notificationApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";

const NotifyModal = ({ handleModalClose, data }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});

  const [rowId, setRowId] = useState("");
  const [userSlug, setUserSlug] = useState("");

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const [sendNotificationToUser, { isLoading }] =
    useSentNotificationUserMutation();

  useEffect(() => {
    if (data) {
      setUserSlug(data.slug);
    }
  }, [data, mounted]);

  const handleSend = async (data) => {
    data.user_slugs = [`${userSlug}`];
    console.log(data);
    try {
      const response = await sendNotificationToUser(data);

      // console.log(response);

      if (!response.error && response.data.code === 200) {
        SuccessToast(response?.data.message);
        reset();
        handleModalClose();
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        console.warn(error);

        Toast("An error occurred during send message", "error");
      }
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      {errors
        ? errors?.title
          ? Toast(errors?.title?.message, "error")
          : Toast(errors?.message?.message, "error")
        : null}
      <ModalContainer handleClose={handleModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">Notify User</p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <p className="text-body-tertiary">Username: {data.name}</p>
            <Form onSubmit={handleSubmit(handleSend)}>
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">Title</p>
                  <Controller
                    control={control}
                    name="title"
                    rules={{
                      required: "The title is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        type="text"
                        value={value}
                        onChange={onChange}
                        innerRef={ref}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">Message</p>
                  <Controller
                    control={control}
                    name="message"
                    rules={{
                      required: "Notification Message is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        type="textarea"
                        as="textarea"
                        rows={5}
                        value={value}
                        onChange={onChange}
                        innerRef={ref}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      isLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={isLoading ? <BeatLoader size={10} /> : "Send"}
                    // onClick={handleSubmit}
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default NotifyModal;
