import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

const CustomSpinner = ({}) => {
  return (
    <Row
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <Col className="text-center">
        <Spinner animation="grow" />
      </Col>
    </Row>
  );
};

export default CustomSpinner;
